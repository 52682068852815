import bodyScrollLock from '../helpers/bodyScrollLock'
import { trapFocus } from '../helpers/trapFocus'

class Lightbox {
  constructor(el) {
    this.trigger = el
    this.lightbox = document.querySelector('[data-lightbox]')
    this.closeButton = this.lightbox.querySelector('[data-lightbox-close]')
    this.contentArea = this.lightbox.querySelector('[data-content]')
    this.target = this.trigger.dataset.target
    this.content = document.getElementById(this.target)
    this.html = null

    this.trigger.addEventListener('click', this.handleClick.bind(this))
    this.hidden = true
  }

  theme(trigger) {
    const { theme } = trigger.dataset
    return theme || 'green'
  }

  bgColor(trigger) {
    const { lightboxColor } = trigger.dataset
    return lightboxColor || 'steel'
  }

  open() {
    this.lightbox.hidden = false

    if (!this.html) {
      this.html = this.content.innerHTML
    }

    this.contentArea.innerHTML = `${this.html}`
    this.content.innerHTML = ''
    this.trigger.setAttribute('aria-expanded', true)
    this.lightbox.setAttribute('aria-hidden', false)

    setTimeout(() => {
      this.lightbox.classList.add('is-active')
      bodyScrollLock(true)

      this.closeButton.focus()
      this.attachKeyDownEvents()

      // Find any close buttons that were previously hidden
      this.attachCloseEvents()
    }, 10)
  }

  attachKeyDownEvents() {
    this.lightbox.addEventListener('keydown', (e) => {
      const escIsPressed = e.keyCode === 27 || e.key === 'Esc'

      trapFocus(e, this.lightbox)

      if (escIsPressed) {
        this.close(e)
      }
    })
  }

  attachCloseEvents() {
    const closeButtons = [
      ...this.lightbox.querySelectorAll('[data-lightbox-close]'),
    ]

    closeButtons.forEach((el) => {
      el.addEventListener('click', this.close.bind(this))
    })
  }

  close(e) {
    e.preventDefault()
    e.stopPropagation()
    this.lightbox.classList.remove('is-active')
    bodyScrollLock(false)

    setTimeout(() => {
      this.trigger.setAttribute('aria-expanded', false)
      this.lightbox.setAttribute('aria-hidden', true)
      this.lightbox.hidden = true
      this.contentArea.innerHTML = ''
      this.content.innerHTML = `${this.html}`
    }, 300)

    this.trigger.focus()
  }

  handleClick(e) {
    e.preventDefault()

    if (this.hidden) {
      this.open()
    }
  }
}

export default Lightbox
