class Cta {
  constructor(el) {
    this.el = el
    this.buttons = [...el.querySelectorAll('a')]

    this.buttons.forEach((button) => {
      button.addEventListener('mouseover', this.handleHover.bind(this))
      button.addEventListener('mouseout', this.handleMouseout.bind(this))
    })
  }

  handleHover(e) {
    if (e.target.href) {
      this.el.classList.add('is-hovered')
      console.log(e.target.href)
    }
  }

  handleMouseout(e) {
    this.el.classList.remove('is-hovered')
  }
}

export default Cta
