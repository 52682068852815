import resize, { minDesktop } from './helpers/resize'
import bodyScrollLock from './helpers/bodyScrollLock'
import { trapFocus } from './helpers/trapFocus'

class Menu {
  constructor(el) {
    this.el = el
    this.menuButton = el.querySelector('[data-menu-button]')
    this.menuContent = el.querySelector('[data-menu-content]')
    this.isHidden = true

    this.menuButton.addEventListener('click', this.toggleMenu.bind(this))

    /* Events */
    this.trapFocus = this.trapFocusInMenu.bind(this)

    this.setInitialStyles()
    resize(this.setInitialStyles.bind(this))
  }

  setInitialStyles() {
    const headerHeight = this.el.clientHeight

    if (minDesktop()) {
      this.menuContent.style.height = `${window.innerHeight}px`
    } else {
      this.menuContent.style.height = `${window.innerHeight - headerHeight}px`
      this.menuContent.style.top = `${headerHeight}px`
    }
  }

  trapFocusInMenu(e) {
    const escIsPressed = e.keyCode === 27 || e.key === 'Esc'

    trapFocus(e, this.el)

    if (escIsPressed) {
      this.close()
      this.menuButton.focus()
    }
  }

  open() {
    this.menuContent.hidden = false

    setTimeout(() => {
      document.body.classList.add('is-menu-open')
      bodyScrollLock(true)
      this.menuButton.querySelector('[data-text]').innerText = 'Close'
      this.el.addEventListener('keydown', this.trapFocus)
      this.isHidden = false
    }, 100)
  }

  close() {
    document.body.classList.remove('is-menu-open')
    bodyScrollLock(false)
    this.menuButton.querySelector('[data-text]').innerText = 'Menu'
    this.el.removeEventListener('keydown', this.trapFocus)
    this.isHidden = true

    setTimeout(() => {
      this.menuContent.hidden = true
    }, 300)
  }

  handleClick() {
    this.menuButton.setAttribute('aria-expanded', this.hidden)

    if (this.hidden) {
      this.open()
    } else {
      this.close()
    }
  }

  toggleMenu(e) {
    this.menuButton.setAttribute('aria-expanded', this.isHidden)

    if (this.isHidden && e.target === this.menuButton) {
      this.open()
    } else {
      this.close()
    }
  }
}

export default Menu
