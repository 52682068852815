const loadBundles = () => {
  /* Galleries */
  const galleries = [
    ...document.querySelectorAll('.wp-block-gallery'),
    ...document.querySelectorAll('[data-gallery]'),
  ]

  if (galleries.length) {
    galleries.forEach((gallery) => {
      import('../components/gallery.js').then((component) =>
        component.render(gallery)
      )
    })
  }

  /* Share buttons */
  if (document.querySelector('.share-btn')) {
    import('share-buttons').then(() => console.log('share'))
  }
}

export default loadBundles
