import resize from '../helpers/resize'

const iframes = [...document.querySelectorAll('iframe')]

const doResize = () => {
  iframes.forEach((el) => {
    const w = el.getAttribute('width')
    const h = el.getAttribute('height')

    if (!w || !h) return

    if (!embedIsVideo(el)) return

    el.style.setProperty('--x', w)
    el.style.setProperty('--y', h)
  })
}

const embedIsVideo = (iframe) => {
  const src = iframe.getAttribute('src')

  return src.includes('youtube') || src.includes('vimeo')
}

const iframeResizer = () => {
  doResize()
  resize(doResize)
}

export default iframeResizer
